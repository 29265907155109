#dashboard {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.dashboard {
  font-family: "Arial", sans-serif;
  color: #333;
  height: 100vh;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.toolbar {
  padding: 10px;
  background-color: #007bff;
  display: flex;
  justify-content: center;
  gap: 50px;
  border-radius: 10px 10px 0 0;
}

.toolbar-button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.toolbar-button:hover {
  background-color: #e0e0e0;
}

.content {
  background-color: #f8f9fa;
  padding: 20px;
  height: 60dvh;
  border-radius: 0 0 10px 10px;
  max-height: 60dvh;
  overflow-y: auto;
}

.dashboard-table {
  width: 100%;
  border-collapse: collapse;
}

.dashboard-table thead {
  background-color: #007bff;
  color: white;
}

.dashboard-table th,
.dashboard-table td {
  padding: 10px;
  border: 1px solid #dee2e6;
}

.dashboard-table th {
  text-align: left;
}

.checkProyect {
  display: none;
}
tbody {
  color: #8a8a8a;
  max-height: 400px;
  overflow-y: auto;
}

tbody tr.selectedTr {
  background: #007bff;
  color: #fff;
}
