header {
  width: 5%;
  background: #8dd3f2;
  border-bottom: 1px solid #000;
  height: 100%;
  position: absolute;
  left: 0;
}
header:hover {
  width: 10%;
  align-items: flex-start;
  margin-left: -20px;
}
header ul {
  width: 100%;
  display: flex;
  list-style: none;
  gap: 50px;
  font-weight: 700;
  color: #000;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
  margin-left: -40px;
}
header ul li {
  cursor: pointer;
}
header:hover ul {
  align-items: flex-start;
  margin-left: -10px;
}
nav.dropdown-menu {
  position: absolute;
  top: 70px;
  left: 110px;
  background: #fff;
  width: 300px;
  height: max-content;
  padding: 20px;
  border-radius: 10px;
}
.dropdown-menu ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0 !important;
  gap: 25px;
  width: 90%;
}
