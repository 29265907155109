.cargar_comensales.cargar_clientes .toolbar {
  padding: 10px;
  background-color: #007bff;
  display: flex;
  justify-content: center;
  gap: 50px;
  border-radius: 10px 10px 0 0;
}
.cargar_comensales.cargar_clientes .content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.buscar-cliente-container input {
  width: 90%;
  height: 30px;
  border-radius: 10px;
  border: 2px solid #007bff;
  padding-left: 10px;
}
.buscar-cliente-container input:focus-visible {
  outline: none;
  border: 3px solid #007bff;
}
