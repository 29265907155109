.containerLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100vh;
    background: #b8b8b877;
    top: 0;
    left: 0;
    z-index: 999999999999;
}

.loader {
    width: 30px;
    height: 30px;
    border: 8px solid #0866ff;
    border-radius: 50px;
    animation: girarLoader infinite linear 0.7s;
    box-shadow: 0 3px 15px -3px #515151;
    background: transparent;
}

@keyframes girarLoader {
    100% {
        transform: rotateZ(360deg);
    }
}