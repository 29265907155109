body {
  height: 100%;
  background: grey;
}

.login-container {
  margin: 20px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 10px;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
  background: #0866ff;
  padding: 20px;
  color: #fff;
  border-radius: 10px;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input,
.input-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 50px;
}

.submit-button,
.cancel-button {
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
}

.submit-button {
  background-color: green;
}

.cancel-button {
  background-color: red;
}

/* Estilos para pantallas más grandes */
@media (min-width: 600px) {
  .login-container {
    max-width: 50%;
    margin: 100px auto;
  }

  .buttons {
    flex-direction: row;
    justify-content: space-between;
  }
}
