.cargar_comensales .toolbar {
  display: flex;
  justify-content: flex-end;
  padding: 10px 30px;
}
.cargar_comensales {
  width: 70%;
  margin: auto;
  padding-top: 5%;
}
.cargar_comensales .content form {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}
.cargar_comensales .content form label p {
  color: #000;
  font-weight: 700;
}
.cargar_comensales
  .content
  form
  label
  input:not(.cargar_comensales .content form label input[type="file"]),
.cargar_comensales .content form label select,
.cargar_comensales .content form button {
  height: 25px;
  border-radius: 5px;
  border: 1px solid #007bff;
  color: #007bff;
  font-weight: 700;
  width: 150px;
  text-align: center;
}
.file-upload {
  cursor: pointer;
  height: 115px;
  border-radius: 5px;
  border: 1px solid #007bff;
  color: #007bff;
  font-weight: 700;
  width: 70%;
  text-align: center;
  line-height: 25px;
  display: inline-block;
  margin-bottom: 20px;
}
.file-upload:hover {
  background-color: #0056b3;
  color: white;
}
.container_selects {
  display: flex;
  align-items: center;
  gap: 21.5%;
  width: 70%;
  margin-bottom: 20px;
}
