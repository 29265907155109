.ingreso-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ingreso-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 60%;
  height: 70%;
  overflow-y: auto;
}

.ingreso-modal-header {
  margin-bottom: 20px;
}

.ingreso-modal-header h2 {
  margin-top: 0;
  text-align: center;
  margin-bottom: 20px;
  background: #0866ff;
  padding: 20px;
  color: #fff;
  border-radius: 10px;
}

.ingreso-modal-body {
  padding: 20px;
  background: #1a6bc3;
  border-radius: 10px;
}

.ingreso-modal-body label {
  display: block;
  margin-bottom: 10px;
}

.ingreso-modal-body input {
  margin-right: 10px;
  height: 100px;
  width: 100%;
  font-size: 30px;
  color: #fff;
  background: #000;
  border: none;
  text-align: center;
}

.person-data {
  background-color: #000;
  color: #fff;
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
}

.bar_white {
  height: 50px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bar_white p {
  font-size: xx-large;
}

.container_bar_white {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.container_info_user {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  background: #b5c9e3;
  padding: 15px;
  width: 50%;
  border-radius: 10px;
}

.comedor,
.tipo_servicio,
.fecha {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.info label {
  height: 40px;
  background: #498cbd;
  color: #fff;
  border-radius: 20px 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  width: 30%;
}
.info input {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 40px;
  background: #fff;
  color: #757575;
  border-radius: 0 20px 20px 0;
  border: none;
  border-left: 1px solid #000;
  text-align: center;
  width: 70%;
}

.countPersons {
  padding: 0 35px;
  background: #4283b6;
  color: #fff;
  font-size: x-large;
  border-radius: 10px;
}
