#reporte_atencion_comedor .cargar_comensales .toolbar {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  color: #fff;
}
#reporte_atencion_comedor .content form label {
  display: flex;
  align-items: center;
  width: 100%;
}
#reporte_atencion_comedor .content form label p {
  width: 15%;
  padding: 5px 0;
  background: #007bff;
  color: #fff !important;
  text-align: center;
}
#reporte_atencion_comedor .content form label input,
#reporte_atencion_comedor .content form label select,
#reporte_atencion_comedor .content form button {
  height: 30px !important;
  width: 85% !important;
}
#reporte_atencion_comedor .content form button {
  width: 100% !important;
}
