#modal-mantenimiento {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(255, 255, 255);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-out;
}

.inputs_modal_post {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.inputs_modal_post input, .inputs_modal_post select {
  height: 35px;
  border-radius: 5px;
  border: 1px solid #007bff;
  padding: 0 10px;
  transition: border 0.3s ease;
}

.inputs_modal_post input:focus-visible, .inputs_modal_post select:focus-visible {
  outline: none;
  border: 2px solid #0056b3;
}

.container_form form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.save-button,
.cancel-button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.save-button {
  background-color: #007bff;
  color: white;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

.save-button:hover {
  background-color: #0056b3;
}

.cancel-button:hover {
  background-color: #d32f2f;
}

.container_buttons_mantenimiento {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
